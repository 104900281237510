import GoBackButton from 'components/GoBackButton';
import InputField from 'components/InputField';
import useAppSelector from 'hooks/useAppSelector';
import { Button } from 'ncoded-component-library';
import React, { useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import authSelectors from 'store/selectors/auth.selectors';
import { required } from 'validations';
import { ReactComponent as IconClose } from 'icons/Close.icon.svg';
import confirm from 'modules/confirm';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import api from 'api';
import { updateUser } from 'store/slices/auth.slice';
import { SchoolGroupStructureType } from 'searchality-data';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';
import useReverseGeocodingFeature from 'hooks/useReverseGeocodingFeature';

import './SchoolInformationEdit.styles.scss';
import './SchoolInformationEdit.styles.responsive.scss';

const SchoolInformationEdit: React.FC = () => {
  const { schoolId } = useParams();
  const user = useAppSelector(authSelectors.selectUser);

  const { getAddressObject } = useReverseGeocodingFeature();

  const hideCounty =
    user?.schoolGroup?.structureType === SchoolGroupStructureType.GROUP;

  const {
    schoolGroup: { schools, structureType, isTaxLocationValid, county },
  } = user;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValues = useMemo(() => {
    const selectedSchool = schools.find(({ _id }) => {
      return _id === schoolId;
    });

    if (!selectedSchool) {
      return {};
    }
    const { name, website, city, state, postcode } = selectedSchool;
    return {
      name,
      website,
      city,
      state,
      postcode,
      county,
    };
  }, [schoolId, schools, county]);

  const handleClose = useCallback(async () => {
    await confirm({
      title: t('myAccount.SchoolGroupInfo.cancel'),
      onSubmit: () => {
        navigate(
          structureType === SchoolGroupStructureType.STANDALONE
            ? '/my-account/school-settings'
            : '/my-account/group-settings',
        );
      },
    });
  }, [navigate, structureType, t]);

  const handleSubmit = useCallback(
    async (values: {
      schoolName?: string;
      website?: string;
      city: string;
      state: string;
      postcode: string;
    }) => {
      try {
        const { data } = await api.schools.updateSchoolInformation(
          values,
          schoolId,
        );
        const userCopy = structuredClone(user);

        const newSchools = userCopy.schoolGroup.schools.map((school) => {
          if (schoolId === school._id) {
            return data;
          }
          return school;
        });

        userCopy.schoolGroup.schools = newSchools as any;
        userCopy.schoolGroup.isTaxLocationValid =
          data.schoolGroup.isTaxLocationValid;

        dispatch(updateUser(userCopy));
        dispatch(popSuccess(t('successfullyEditSchoolInformation')));
        navigate('../');
      } catch (e) {
        dispatch(popServerError(e));
      }
    },
    [dispatch, navigate, schoolId, t, user],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        dirty,
        invalid,
        submitting,
        form: { change },
        values: { state },
      }) => (
        <form onSubmit={handleSubmit} className="school-information-edit-form">
          <GoBackButton className="school-information-edit-form__go-back" />
          <Button
            variant="link"
            iconPosition="left"
            icon={<IconClose />}
            className="school-information-edit-form__close"
            onClick={handleClose}
          >
            {t('close')}
          </Button>
          <header>
            <h1>{t('myAccount.SchoolGroupInfo.title')}</h1>
            <p>{t('myAccount.SchoolGroupInfo.description')}</p>
          </header>
          <Field
            name="name"
            component={InputField}
            label={t('schoolName')}
            validate={required()}
            required
          />
          <Field
            name="website"
            component={InputField}
            label={t('schoolWebsite')}
            validate={required()}
            required
          />
          <Field
            name="city"
            label={t('city')}
            validate={required()}
            component={MapboxAutocompleteField}
            required
            callback={async (selectedOption: any, feature: any) => {
              const address = await getAddressObject(feature);

              if (address?.state) {
                change('state', address.state);
              }
              if (address?.postcode) {
                change('postcode', address.postcode);
              }
            }}
            mapFeaturesToOptions={(feature: any) => {
              const { place_name } = feature;
              return {
                label: place_name,
                value: place_name,
              };
            }}
            mapboxParams={{
              types: ['place'],
            }}
          />

          {!hideCounty && (
            <Field
              name="county"
              component={MapboxAutocompleteField}
              mapFeaturesToOptions={(feature: any, alo: any) => {
                const { place_name } = feature;
                return {
                  label: place_name,
                  value: place_name,
                };
              }}
              label={t('county')}
              key={state}
              mapboxParams={{
                types: ['district', 'region'],
              }}
            />
          )}
          <div className="school-information-edit-form__row-container">
            <Field
              name="state"
              searchable
              label={t('District.Schools.statePlaceholder')}
              required
              validate={required()}
              component={MapboxAutocompleteField}
              mapboxParams={{
                types: ['district', 'region'],
              }}
              key={state}
              mapFeaturesToOptions={(feature: any) => {
                const { place_name } = feature;
                return {
                  label: place_name,
                  value: place_name,
                };
              }}
            />
            <Field
              name="postcode"
              component={InputField}
              label={t('zipcode')}
              required
              validate={required()}
            />
          </div>

          {!isTaxLocationValid && (
            <p className="not-valid">{t('locationNotValid')}</p>
          )}
          <Button type="submit" disabled={invalid || submitting || !dirty}>
            {t('save')}
          </Button>
        </form>
      )}
    />
  );
};

export default SchoolInformationEdit;
