import useAppSelector from 'hooks/useAppSelector';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SchoolGroupStructureType } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';

export default function () {
  const [currentSchooldIndex, setCurrentSchooldIndex] = useState(0);

  const { schoolGroup } = useAppSelector(authSelectors.selectUser);
  const hideCounty =
    schoolGroup?.structureType === SchoolGroupStructureType.GROUP;
  const { schools, county: schoolGroupCounty } = schoolGroup;

  const { t } = useTranslation();

  const length = schools?.length;

  const previousStep = useCallback(() => {
    setCurrentSchooldIndex((prev) => (prev === 0 ? length - 1 : prev - 1));
  }, [length]);

  const nextStep = useCallback(() => {
    setCurrentSchooldIndex((prev) => (prev === length - 1 ? 0 : prev + 1));
  }, [length]);

  const list = useMemo(() => {
    if (!schools?.length) return [];

    const { city, name, postcode, state, website, county } =
      schools[currentSchooldIndex];

    const result = [
      {
        label: t('schoolName'),
        value: name,
      },
      {
        label: t('schoolWebsite'),
        value: website,
      },
      {
        label: t('cityAndState'),
        value: `${city} - ${state}`,
      },
      {
        label: t('postcode'),
        value: postcode,
      },
    ];

    return hideCounty
      ? result
      : [
          ...result,
          {
            label: t('county'),
            value: (county || schoolGroupCounty)?.split(',')?.[0],
          },
        ];
  }, [currentSchooldIndex, hideCounty, schoolGroupCounty, schools, t]);

  return { list, currentSchooldIndex, schools, nextStep, previousStep };
}
