import { AxiosError } from 'axios';
import { TEMPORARY_NAME } from 'constants/general';
import DOMPurify from 'dompurify';
import { DateTime } from 'luxon';
import { SchoolGroup } from 'models/School';
import {
  ApplicationType,
  LanguageLevel,
  SchoolGroupStructureType,
  SchoolResidentialType,
  VacancyType,
} from 'searchality-data';
import { EMAIL_REGEX } from 'validations';

function isTouchDevice(): boolean {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query: string) {
    return window.matchMedia(query).matches;
  };

  if ('ontouchstart' in window || 'DocumentTouch' in window) {
    return true;
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'ncoded', ')'].join(
    '',
  );
  return mq(query);
}

export const parseStringValue = (value: any) =>
  /^\d+$/.test(value)
    ? +value
    : /^(true|false)$/.test(value)
    ? JSON.parse(value)
    : value;

export function convertFormDataToJSONObject(formData: FormData) {
  const obj: any = {};
  formData.forEach((val, key) => {
    const isArray = key.includes('[]') || key.includes('files');

    if (isArray) {
      const newKey = key.split('[]')[0];
      if (!obj[newKey]) obj[newKey] = [];
      obj[newKey].push(parseStringValue(val));
    } else {
      obj[key] = parseStringValue(val);
    }
  });
  return obj;
}

export const convertObjToFormData = (
  obj: Record<string, any>,
  formData = new FormData(),
  path = '',
) => {
  if (obj === undefined) return;

  for (const prop in obj) {
    const newPath = path ? `${path}[${prop}]` : prop;
    if (typeof obj[prop] !== 'object') {
      if (obj[prop] instanceof File) {
        const file: File = obj[prop];
        formData.append(newPath, file, file.name);
      } else {
        formData.append(newPath, obj[prop]);
      }
    } else if (obj[prop] === null) {
      formData.append(newPath, obj[prop]);
    } else {
      convertObjToFormData(obj[prop], formData, newPath);
    }
  }

  return formData;
};

export function debounce(
  func: (...args: any[]) => void,
  wait: number,
  isImmediate = false,
) {
  let timeout: NodeJS.Timeout | null;
  return function (...args: any[]) {
    const later = () => {
      timeout = null;
      if (!isImmediate) {
        func(...args);
      }
    };
    const callNow = isImmediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = global.setTimeout(later, wait);
    if (callNow) {
      func(...args);
    }
  };
}
const isAxiosError = (e: AxiosError | unknown): e is AxiosError =>
  'message' in (e as AxiosError) || 'response' in (e as AxiosError);

function toastError(e: AxiosError | unknown) {
  if (isAxiosError(e)) {
    console.error({ text: e.message || (e?.response.data as any)?.message });
    return;
  }

  console.error({ text: 'Error' });
}

const getPaginationPages = (currentStep: number, lastPage: number) =>
  lastPage < 6
    ? Array(lastPage)
        .fill(1)
        .map((number, index) => number + index)
    : currentStep < 4
    ? [1, 2, 3, 4, '...', lastPage]
    : currentStep >= lastPage - 2
    ? [1, '...', lastPage - 3, lastPage - 2, lastPage - 1, lastPage]
    : [
        1,
        '...',
        currentStep - 1,
        currentStep,
        currentStep + 1,
        '...',
        lastPage,
      ];

function getStringError(
  error: AxiosError<{
    message?: string | Array<string>;
  }>,
) {
  if (!error?.response?.data && !error?.message) return "Couldn't parse error";

  let { response: { data: { message } = {} } = {} } = error;

  message = message || error?.message;

  if (!message) return "Couldn't parse error";

  message =
    typeof message === 'string'
      ? message
      : typeof message?.[0] === 'string'
      ? message[0]
      : 'Invalid data';

  return message;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop(): any {}

export const scrollToRef = (
  ref: React.MutableRefObject<HTMLDivElement>,
  scrollOptions: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start' },
) => {
  ref?.current?.scrollIntoView(scrollOptions);
};

export const scrollToFirstValidationError = (
  scrollOptions: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'center',
  },
): void => {
  document.querySelector(`[class*="error"]`)?.scrollIntoView(scrollOptions);
};

function getKeyByValue(object: Record<string, string>, value: string) {
  return Object.keys(object).find((key) => object[key] === value);
}

function lightOrDark(initialColor: string) {
  if (!initialColor) return;

  // Variables for red, green, blue values
  let r, g, b;
  let color;
  // Check the format of the color, HEX or RGB?
  if (initialColor.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = initialColor.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    );

    r = parseInt(color[1]);
    g = parseInt(color[2]);
    b = parseInt(color[3]);
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +(
      '0x' +
      initialColor.slice(1).replace(initialColor.length < 5 && /./g, '$&$&')
    );

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
}

export const getNameInitials = (firstName: string, lastName: string) => {
  if (firstName === TEMPORARY_NAME || lastName === TEMPORARY_NAME) return 'NN';

  if (firstName && lastName) {
    return firstName[0] + lastName[0];
  }

  if (firstName) {
    return firstName[0];
  }

  if (lastName) {
    return lastName[0];
  }

  return '';
};

export const formatDate = (date: Date | string) => {
  const { day, monthLong, year } = DateTime.fromJSDate(new Date(date));
  return `${day} ${monthLong} ${year}`;
};

export const getEmailsFromString = (email: string) => {
  const newEmailsSet = new Set(
    email
      .split(',')
      .map((el) => el.trim())
      .filter((el) => el.match(EMAIL_REGEX)),
  );

  return Array.from(newEmailsSet);
};

export const numberPriceToCurrencyString = (value: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
  }).format(value);

const uniqueId = () => {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36).substr(2);
  return dateString + randomness;
};

const normalizePhone = (value: string) => {
  if (!value) return value;

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 6)
    return `${onlyNums.slice(0, 3)} - ${onlyNums.slice(3, 6)}`;
  if (onlyNums.length <= 10)
    return `${onlyNums.slice(0, 3)} - ${onlyNums.slice(
      3,
      6,
    )} - ${onlyNums.slice(6, 10)}`;
  return `${onlyNums.slice(0, 3)} - ${onlyNums.slice(3, 6)} - ${onlyNums.slice(
    6,
    10,
  )} - ${onlyNums.slice(10)}`;
};

const parseUrl = (url: string) => (/^http(s)?.*/.test(url) ? url : `//${url}`);

const isLanguageLevelOkay = (
  requiredLevel: LanguageLevel,
  level: LanguageLevel,
) => {
  const tmp = Object.values(LanguageLevel);

  return (
    tmp.findIndex((el) => el === level) <=
    tmp.findIndex((el) => el === requiredLevel)
  );
};

const calculateSortValue = (prevValue: string, currentValue: string) => {
  if (prevValue === currentValue) {
    return `-${currentValue.split(' ').join(' -')}`;
  }
  return currentValue;
};

const mapVacancyTypeToApplicationType = (type: VacancyType) =>
  type === VacancyType.NON_ACADEMIC
    ? ApplicationType.NON_ACADEMIC
    : ApplicationType?.ACADEMIC;

function createMarkup(html: string | Node) {
  return {
    __html: DOMPurify.sanitize(html),
  };
}

function mapRouteToResidentialType(residentialType: string) {
  if (!residentialType) return undefined;

  if ('boarding' === residentialType) {
    return SchoolResidentialType.BOARDING_SCHOOL;
  }
  if ('day' === residentialType) {
    return SchoolResidentialType.DAY_SCHOOL;
  }
  return SchoolResidentialType.MIXED;
}

function mapResidentialTypeToRoute(residentialType: SchoolResidentialType) {
  if (!residentialType) return undefined;

  if (SchoolResidentialType.BOARDING_SCHOOL === residentialType) {
    return 'boarding';
  }
  if (SchoolResidentialType.DAY_SCHOOL === residentialType) {
    return 'day';
  }
  return 'mix';
}
function isObjectEmpty(obj: Record<string, any>) {
  return Object.keys(obj).length === 0;
}

function hideCounty(group: SchoolGroup) {
  return (
    group.type !== 'public school' &&
    group.structureType === SchoolGroupStructureType.GROUP
  );
}
export default {
  noop,
  toastError,
  isTouchDevice,
  parseStringValue,
  convertObjToFormData,
  convertFormDataToJSONObject,
  debounce,
  getStringError,
  getPaginationPages,
  scrollToRef,
  calculateSortValue,
  scrollToFirstValidationError,
  getKeyByValue,
  lightOrDark,
  uniqueId,
  normalizePhone,
  parseUrl,
  isLanguageLevelOkay,
  mapVacancyTypeToApplicationType,
  createMarkup,
  mapRouteToResidentialType,
  mapResidentialTypeToRoute,
  isObjectEmpty,
  hideCounty,
};
