import GoBackButton from 'components/GoBackButton';
import Input from 'components/Input';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import { statesOptions } from 'constants/states';
import useAppSelector from 'hooks/useAppSelector';
import { Button } from 'ncoded-component-library';
import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import authSelectors from 'store/selectors/auth.selectors';
import { composeValidators, required, websiteValidation } from 'validations';
import { ReactComponent as IconClose } from 'icons/Close.icon.svg';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateUser } from 'store/slices/auth.slice';
import confirm from 'modules/confirm';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import api from 'api';

import './SchoolGroupInfo.styles.scss';
import './SchoolGroupInfo.styles.responsive.scss';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';

const SchoolGroupInfo: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { schoolGroup, ...restOfUser } = useAppSelector(
    authSelectors.selectUser,
  );

  const { name, state, website, postcode, schools, schoolCount } = schoolGroup;

  const handleClose = useCallback(async () => {
    await confirm({
      title: t('myAccount.SchoolGroupInfo.cancel'),
      onSubmit: () => {
        navigate('/my-account/group-settings');
      },
    });
  }, [navigate, t]);

  const handleSubmit = useCallback(
    async (values: {
      website: string;
      state: 'string';
      schoolCount?: number;
    }) => {
      try {
        const {
          data: { schools: resSchools, ...rest },
        } = await api.schools.updateSchoolGroup({
          ...values,
          state: schoolGroup.type === 'public school' ? values.state : null,
          schoolCount: +values.schoolCount,
        });
        dispatch(
          updateUser({ ...restOfUser, schoolGroup: { ...rest, schools } }),
        );
        dispatch(popSuccess(t('schoolGroupUpdateSuccess')));
        navigate('../');
      } catch (error) {
        dispatch(popServerError(error));
      }
    },
    [dispatch, navigate, restOfUser, schoolGroup.type, schools, t],
  );

  const validate = useCallback(
    (values: any) => {
      const { schoolCount } = values;

      const count = schools.length;

      if (schoolCount < count) {
        return {
          schoolCount: t('myAccount.SchoolGroupInfo.schoolCountError', {
            count,
          }),
        };
      }

      return {};
    },
    [schools.length, t],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ state, website, postcode, schoolCount }}
      validate={validate}
      render={({ handleSubmit, invalid, submitting, pristine }) => (
        <form onSubmit={handleSubmit} className="school-group-info-form">
          <GoBackButton className="school-group-info-form__go-back" />
          <Button
            variant="link"
            iconPosition="left"
            icon={<IconClose />}
            className="school-group-info-form__close"
            onClick={handleClose}
          >
            {t('close')}
          </Button>
          <header>
            <h1>{t('myAccount.SchoolGroupInfo.title')}</h1>
            <p>{t('myAccount.SchoolGroupInfo.description')}</p>
          </header>
          <Input
            readOnly
            value={name}
            label={t('myAccount.SchoolGroupInfo.name')}
          />
          <Field
            name="website"
            component={InputField}
            label={t('myAccount.SchoolGroupInfo.website')}
            validate={composeValidators(required(), websiteValidation())}
            required
          />
          {schoolGroup.type === 'public school' && (
            <>
              <Field
                name="state"
                options={statesOptions}
                component={SelectField}
                label={t('state')}
                searchable
                required
                validate={required()}
              />
              <Field
                name="county"
                component={MapboxAutocompleteField}
                mapFeaturesToOptions={(feature: any, alo: any) => {
                  const { place_name } = feature;
                  return {
                    label: place_name,
                    value: place_name,
                  };
                }}
                label={t('county')}
                key={state}
                mapboxParams={{
                  types: ['district', 'region'],
                }}
              />
            </>
          )}
          <Field
            name="schoolCount"
            component={InputField}
            label={t('myAccount.SchoolGroupInfo.schoolCount')}
            required
            validate={required()}
            onlyNumbers
            maxLength={4}
          />
          <Button type="submit" disabled={invalid || submitting || pristine}>
            {t('save')}
          </Button>
        </form>
      )}
    />
  );
};

export default SchoolGroupInfo;
